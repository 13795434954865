var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('messages.user'))+" ")]),_c('div',{staticClass:"table-toolbar"},[(_vm.$can('user.create'))?_c('add',{attrs:{"to":{ name: 'dashboard.user-create' }}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users.data,"server-items-length":_vm.users.total,"options":_vm.pagination,"items-per-page":parseInt(_vm.users.per_page),"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false },"loading":_vm.loading,"must-sort":""},on:{"update:options":[function($event){_vm.pagination=$event},_vm.getUsers]},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated_at,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard.user-view', params: { id: item.id }})}}},on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('action.preview')))])]),(_vm.$can('user.update'))?_c('DataTableAction',{attrs:{"text":_vm.$t('action.edit'),"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard.user-update', params: { id: item.id }})}}}):_vm._e(),(_vm.$can('user.update.password'))?_c('DataTableAction',{attrs:{"icon":"mdi-key","text":_vm.$t('action.edit_password')},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard.user-update-password', params: { id: item.id }})}}}):_vm._e()]}}],null,true)})],1),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }