<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ $t('messages.user') }}
        </v-card-title>
        <div class="table-toolbar">
          <add
            v-if="$can('user.create')"
            :to="{ name: 'dashboard.user-create' }"
          />
        </div>
        <v-data-table
          :headers="headers"
          :items="users.data"
          :server-items-length="users.total"
          :options.sync="pagination"
          :items-per-page="parseInt(users.per_page)"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false }"
          :loading="loading"
          must-sort
          class="elevation-1"
          @update:options="getUsers"
        >
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon
                  small
                  class="mr-1"
                  v-on="on"
                  @click="$router.push({ name: 'dashboard.user-view', params: { id: item.id }})"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>{{ $t('action.preview') }}</span>
            </v-tooltip>
            <DataTableAction
              v-if="$can('user.update')"
              :text="$t('action.edit')"
              icon="mdi-pencil"
              @click="$router.push({ name: 'dashboard.user-update', params: { id: item.id }})"
            />
            <DataTableAction
              v-if="$can('user.update.password')"
              icon="mdi-key"
              :text="$t('action.edit_password')"
              @click="$router.push({ name: 'dashboard.user-update-password', params: { id: item.id }})"
            />
          </template>
        </v-data-table>
      </v-card>
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import pagination from '@/mixins/pagination'
import authHeader from '@/utils'
import { add } from '@/components/actions'
import DataTableAction from '@/components/table-actions/VDataTableAction'

export default {
  name: 'User',
  components: {
    add, DataTableAction
  },
  mixins: [pagination],
  data () {
    return {
      loading: true,
      headers: [
        { text: '#', value: 'id' },
        { text: this.$t('messages.name'), value: 'name' },
        { text: this.$t('messages.email'), value: 'email' },
        { text: this.$t('messages.created_at'), value: 'created_at' },
        { text: this.$t('messages.updated_at'), value: 'updated_at' },
        { text: this.$t('messages.actions'), value: 'action', sortable: false, width: '10%', align: 'right' }
      ],
      users: {
        data: []
      }
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.loading = true
      this.$http
        .get('/users' + this.datatableOptions, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then((response) => {
          this.loading = false
          this.users = response.data
        })
    }
  }
}
</script>
